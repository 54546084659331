import { graphql, Link } from "gatsby";
import React from "react";
import Menu from "../components/common/Menu";
import { GatsbyImage } from "gatsby-plugin-image";
import QuoteRat from "../assets/QuoteRat.png";
import "../css/cementery.css";

const CementeryQuoteRat = ({ data }) => {
  const cemeteryTextTemp = data.sanityPage.cemeteryTextTemp;

  return (
    <div className="quote_rat cementery_quoterat_bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-3">
            <Link to="/cemetery">
              <GatsbyImage
                image={cemeteryTextTemp.back.asset.gatsbyImageData}
                alt={cemeteryTextTemp.back.asset.altText || "back"}
                className="quote_rat_tatoo1 img-fluid"
                loading="eager"
                objectFit="contain"
              />
            </Link>
          </div>
          <div className="col-lg-4 col-md-4 col-6">
            <center>
              <Link to="/">
                <GatsbyImage
                  image={cemeteryTextTemp.logo.asset.gatsbyImageData}
                  alt={cemeteryTextTemp.logo.asset.altText || "Gubler Land"}
                  className="img-fluid quote_rat_tatoo2"
                  loading="eager"
                  objectFit="contain"
                />
              </Link>
            </center>
          </div>
          <div className="col-lg-4 col-md-4 col-3">
            {/*Burger Menu Start-*/}
            <Menu />
            {/*Burger Menu End-*/}
          </div>
          <div className="col-md-12">
            <img src={QuoteRat} className="img-fluid quote_rat_tatoo3" />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <center>
              {cemeteryTextTemp.text.map((item, index) => (
                <div key={index}>
                  <GatsbyImage
                    image={item.image.asset.gatsbyImageData}
                    alt={item.image.alt || "Text"}
                    className="img-fluid quote_rat_tatoo4"
                    loading="lazy"
                    objectFit="contain"
                  />
                  <br />
                </div>
              ))}
              <br />
            </center>
            <br />
            <br />
            <Link to="/paper-termite">
              <GatsbyImage
                image={
                  cemeteryTextTemp.paperTermite.image.asset.gatsbyImageData
                }
                alt={
                  cemeteryTextTemp.paperTermite.image.asset.altText ||
                  "Paper Termite"
                }
                className="img-fluid quote_rat_tatoo23"
                loading="lazy"
                objectFit="contain"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export const query = graphql`
  query MyCemeteryTextQuery($slug: String!) {
    sanityPage(slug: { current: { eq: $slug } }) {
      title
      template
      slug {
        current
      }
      seo {
        title
        pagehandle
        ldSchema
        keywords
        description
      }
      cemeteryTextTemp {
        back {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        logo {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        text {
          image {
            asset {
              altText
              gatsbyImageData(placeholder: BLURRED, formats: WEBP)
            }
          }
          alt
        }
        paperTermite {
          title
          isModal
          image {
            asset {
              altText
              gatsbyImageData(placeholder: BLURRED, formats: WEBP)
            }
          }
          videoUrl
          slug {
            current
          }
        }
      }
    }
  }
`;

export default CementeryQuoteRat;
